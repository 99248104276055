import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'

import TextContent from '../components/text/text'

const Home = ({ data }) => (
  <>
    <SEO {...data.wordpressPage.yoast_meta} title="data.wordpressPage.title" />
    <TextContent content={data.wordpressPage.content} title={data.wordpressPage.title} background={data.wordpressPage.acf.background} />
  </>
)

export default Home

export const query = graphql`
  query($wordpressId: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpressId }) {
      ...SeoFragment
      ...TextFragment
    }
  }
`

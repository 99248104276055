import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'

import Title from '../title'

export const fragment = graphql`
  fragment TextFragment on wordpress__PAGE {
    content
    title
    acf {
      background {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const TextContent = ({ content, title, background }) => (
  <Outer>
    <Inner className="container">
      <Title type="h1" text={title} />
      <Text dangerouslySetInnerHTML={{ __html: content }} />
    </Inner>
    <Image fluid={background.localFile.childImageSharp.fluid} loading="eager" fadeIn={false} style={{ position: 'absolute' }} />
  </Outer>
)

const Outer = styled.section`
  position: relative;
  padding: 8rem 0;
  z-index: 5;

  @media (max-width: 650px) {
    padding: 12rem 0;
  }
`

const Inner = styled.div`
  position: relative;
  max-width: 64rem;
  padding: 10rem 3rem;
  z-index: 5;

  @media (max-width: 650px) {
    padding: 0 4rem;
  }

  h1 {
    font-size: 6.6rem;
    border-bottom: 1px solid #fff;
    padding-bottom: 2.5rem;
    margin-bottom: 0;
  }

  h2 {
    font-size: 2.8rem;
    line-height: 1.3;
    font-weight: 300;
    margin-top: 3rem;
  }

  h3 {
    font-size: 2.2rem;
    line-height: 1.3;
    font-weight: 300;
    margin-top: 3rem;
  }

  p {
    opacity: 0.8;
  }

  ul,
  ol {
    font-family: ${props => props.theme.fonts.roboto};
    font-size: 1.6rem;
    line-height: 1.6;
    color: #fff;
    margin-bottom: 1.5rem;
    padding-left: 0.4rem;
    opacity: 0.8;
  }
`

const Text = styled.div`
  position: relative;
  padding: 3rem 0;
  border-bottom: 1px solid #fff;
  padding-bottom: 6rem;

  > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Image = styled(Img)`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`

export default TextContent
